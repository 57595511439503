import { Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import React from 'react'
import { ORIGINS } from '../types/types'

function getUrlExtension(url) {
  if (!url) return ''
  return url.split(/[#?]/)[0].split('.').pop().trim()
}
function getUrlPathname(url) {
  if (!url) return ''
  return url.split('/')[url.split('/').length - 2]
}
function isOriginUrl(url) {
  return url === ORIGINS.VISARTECH || url === ORIGINS.WWW_VISARTECH
}

const parseLink = (elem) => {
  const url = new URL(elem?.attribs?.href)
  const utm = url.search ? url.search : ''
  if (isOriginUrl(url.origin)) {
    return <Link to={url.pathname + utm}>{domToReact(elem.children)}</Link>
  }
  return null
}

function parseImage(elem) {
  const { children } = elem
  const preview = children[0].children.find((t) => t.name === 'img')
  const picture = children[0].children.find((t) => t.name === 'picture')
  const img = picture.children.find((t) => t.name === 'img')

  img.attribs = { ...img.attribs, width: 1200, height: 400 }
  preview.attribs = { ...preview.attribs, width: 20, height: 9 }
}

function parseContentLink(content) {
  return parse(content, {
    replace: (elem) => {
      if (elem.tagName === 'a') {
        return parseLink(elem)
      }
      return elem
    },
  })
}

function parseContentLinkAndImage(content) {
  return parse(content, {
    replace: (elem) => {
      const { children } = elem
      if (elem.tagName === 'a') {
        return parseLink(elem)
      }
      if (
        children?.length > 0 &&
        children[0].attribs?.class?.includes('gatsby-image-wrapper')
      ) {
        parseImage(elem)
      }
      return elem
    },
  })
}

export {
  getUrlExtension,
  getUrlPathname,
  isOriginUrl,
  parseLink,
  parseImage,
  parseContentLink,
  parseContentLinkAndImage,
}

import React, { useState } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'

const useWpContentOverflow = (id) => {
  const [isOverflow, setIsOverflow] = useState(false)

  function toggle() {
    setIsOverflow(!isOverflow)
    if (isOverflow) {
      scrollTo(`#${id}`)
    }
  }

  return {
    isOverflow,
    toggle,
  }
}

export default useWpContentOverflow

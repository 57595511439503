// extracted by mini-css-extract-plugin
export var animateCircle = "one-service-benefits-module--animate-circle---BR0H";
export var bounce = "one-service-benefits-module--bounce--bb0Xx";
export var container = "one-service-benefits-module--container--U1XR4";
export var contentGrid = "one-service-benefits-module--contentGrid--IMshH";
export var item = "one-service-benefits-module--item--IcTo6";
export var itemContent = "one-service-benefits-module--itemContent--3ke1J";
export var itemWrap = "one-service-benefits-module--itemWrap--RpFtg";
export var ldsRing = "one-service-benefits-module--lds-ring--byJ0z";
export var leftItem = "one-service-benefits-module--leftItem--wjDMC";
export var rightItem = "one-service-benefits-module--rightItem--VlunJ";
export var section = "one-service-benefits-module--section--y0+TN";
export var title = "one-service-benefits-module--title--dB1Ia";
import React from 'react'
import Fade from 'react-reveal/Fade'
import className from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import * as style from './one-service-header.module.scss'
import { DEVICE_FRAME_TYPE } from '../../../../types/types'

const { LAPTOP, MOBILE, CONSOLE, GLASSES, MONITOR, TABLET } = DEVICE_FRAME_TYPE
const classes = {
  [LAPTOP]: 'laptopVideoWrapper',
  [MOBILE]: 'mobileVideoWrapper',
  [CONSOLE]: 'consoleVideoWrapper',
  [GLASSES]: 'glassesVideoWrapper',
  [MONITOR]: 'monitorVideoWrapper',
  [TABLET]: 'tabletVideoWrapper',
}

const DeviceImage = ({ frameSrc }) => {
  frameSrc
  switch (frameSrc) {
    case MOBILE:
      return (
        <StaticImage
          layout="fullWidth"
          className="one-service-device-image"
          src="../../../../images/iphone-x-bg.webp"
          alt="mobile"
        />
      )
    case LAPTOP:
      return (
        <StaticImage
          layout="fullWidth"
          className="one-service-device-image"
          src="../../../../images/laptop-bg.webp"
          alt="laptop"
        />
      )
    case TABLET:
      return (
        <StaticImage
          layout="fullWidth"
          className="one-service-device-image"
          src="../../../../images/ipad-pro-bg.webp"
          alt="tablet"
        />
      )
    case GLASSES:
      return (
        <StaticImage
          layout="fullWidth"
          className="one-service-device-image"
          src="../../../../images/glasses-bg.webp"
          alt="glasses"
        />
      )
    case CONSOLE:
      return (
        <StaticImage
          layout="fullWidth"
          className="one-service-device-image"
          src="../../../../images/console-bg.webp"
          alt="console"
        />
      )
    case MONITOR:
      return (
        <StaticImage
          layout="fullWidth"
          className="one-service-device-image"
          src="../../../../images/monitor-bg.webp"
          alt="monitor"
        />
      )
    default:
      return (
        <StaticImage
          layout="fullWidth"
          className="one-service-device-image"
          src="../../../../images/iphone-x-bg.webp"
          alt="mobile"
        />
      )
  }
}
const FramedVideo = ({ videoWrapperClassName, frameSrc, videoSrc, img }) => {
  const poster = img
    ? img.localFile.childImageSharp.gatsbyImageData.images.fallback.src
    : ''

  return (
    <div className="container">
      <div className={className(style.deviceImage, videoWrapperClassName)}>
        <DeviceImage frameSrc={frameSrc} />
        <div className={style.deviceVideoWrapper}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            playsInline
            poster={poster}
            src={videoSrc}
            autoPlay="autoplay"
            muted="muted"
            loop="loop"
          />
        </div>
      </div>
    </div>
  )
}

const OneServiceHeader = ({ title, videoSrc, frameType, data }) => {
  const videoWrapperClassName = classes[frameType]

  return (
    <section className={style.section}>
      <div className={style.titleWrapper}>
        <Fade left>
          <h1 className={style.title}>{title}</h1>
        </Fade>
        <div className={style.scrollDown}>
          <span className={style.scrollDownText}>Scroll down</span>
          <span className={style.scrollDownIcon} />
          <span className={style.scrollDownIcon} />
          <span className={style.scrollDownIcon} />
        </div>
      </div>
      <FramedVideo
        videoWrapperClassName={style[videoWrapperClassName]}
        frameSrc={frameType}
        videoSrc={videoSrc}
        img={data.image}
      />
    </section>
  )
}

export default OneServiceHeader

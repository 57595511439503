import Fade from 'react-reveal/Fade'
import * as React from 'react'
import Section from '../../../common/SectionComponent/Section'
import * as hoverPlate from '../../../reusable/HoverPlates/hover-plates.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
import HoverPlate from '../../../reusable/HoverPlates/HoverPlate'

const OneServicesHoverPlatesSection = ({
  title,
  description,
  data,
  isLight,
}) => (
  <Section isLight={isLight} title={title}>
    <p
      className={
        isLight ? 'text-color__gray--on-light' : 'text-color__gray--on-dark'
      }
    >
      {description}
    </p>
    <Fade>
      <div className={hoverPlate.wrap}>
        {data.map((elem, index) => {
          const props = {
            title: elem.title,
            description: elem.description,
          }
          return (
            <>
              <div className={hoverPlate.elem} key={keyGenerator(index, 1)}>
                <HoverPlate isLight={isLight} {...props} />
              </div>
            </>
          )
        })}
      </div>
    </Fade>
  </Section>
)

export default OneServicesHoverPlatesSection

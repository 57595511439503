// extracted by mini-css-extract-plugin
export var animateCircle = "one-service-core-module--animate-circle--1yPXA";
export var bounce = "one-service-core-module--bounce--OC+3i";
export var coreItem = "one-service-core-module--coreItem--mH5lI";
export var coreItemContainer = "one-service-core-module--coreItemContainer--1L+yc";
export var coreItemDescription = "one-service-core-module--coreItemDescription--E6j36";
export var coreItemTitle = "one-service-core-module--coreItemTitle--R9+iP";
export var description = "one-service-core-module--description--+TJDk";
export var grid2columns = "one-service-core-module--grid2columns--eksUj";
export var ldsRing = "one-service-core-module--lds-ring--w-RLt";
export var section = "one-service-core-module--section--7NTzP";
export var title = "one-service-core-module--title--zGchI";
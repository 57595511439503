import React, { useState } from 'react'
import parse, { domToReact } from 'html-react-parser'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'
import keyGenerator from '../../../../utils/keyGenerator'
import * as style from './one-service-faq.module.scss'
import Section from '../../../common/SectionComponent/Section'
import HiddenScrollItem from '../../../common/HiddenScrollItem/HiddenScrollItem'
import LeftBorderTextComponent from '../../../reusable/LeftBorderTextComponent/LeftBorderTextComponent'
import useWpContentOverflow from '../../../../hooks/useWpContentOverflow'
import { parseContentLink } from '../../../../utils/functions'

const OverflowLeftBorderTextComponent = ({ faqDescription, ...props }) => {
  const { toggle, isOverflow } = useWpContentOverflow('faq')

  const faqDescriptionHTML = parseContentLink(faqDescription)
  const button = {
    text: isOverflow ? 'Show less' : 'Show More',
    arrowDirection: isOverflow ? 'up' : 'down',
    isLight: true,
    onClick: toggle,
  }

  return (
    <LeftBorderTextComponent
      isPrimary
      size="small"
      descriptionClass={style.contentItemDescription}
      description={isOverflow ? faqDescriptionHTML : faqDescriptionHTML[0]}
      button={faqDescriptionHTML.length > 2 && button}
      {...props}
    />
  )
}

const OneServiceFaq = ({ title, description, faqElements }) => {
  const [activeItem, setActiveItem] = useState(0)
  const activeItemToggle = (n) => () => {
    setActiveItem(n)
  }

  return (
    <Section title={title} isLight>
      <HiddenScrollItem id="faq" />
      <p className={style.description}>{description}</p>
      <div className={style.faqItem}>
        {faqElements?.map((item, index) => (
          <Fade key={keyGenerator(index, item.faqTitle)} bottom>
            <OverflowLeftBorderTextComponent
              index={index}
              title={item.faqTitle}
              faqDescription={item.faqDescription}
              activeItem={activeItem}
              onClick={activeItemToggle}
            />
          </Fade>
        ))}
      </div>
    </Section>
  )
}

export default OneServiceFaq

import React from 'react'
import PropTypes from 'prop-types'
import MainLayout from '../layouts/MainLayout'
import OneServiceHeader from '../components/Pages/OneService/OneServiceHeader/OneServiceHeader'
import OneServiceCore from '../components/Pages/OneService/OneServiceCore/OneServiceCore'
import OneServiceBenefits from '../components/Pages/OneService/OneServiceBenefits/OneServiceBenefits'
import OneServicePortfolio from '../components/Pages/OneService/OneServicePortfolio/OneServicePortfolio'
import OneServiceTechnologyMap from '../components/Pages/OneService/OneServiceTechnologyMap/OneServiceTechnologyMap'
import OneServiceFaq from '../components/Pages/OneService/OneServiceFaq/OneServiceFaq'
import OneServiceBlog from '../components/Pages/OneService/OneServiceBlog/OneServiceBlog'
import OneServicesHoverPlatesSection from '../components/Pages/OneService/OneServicesHoverPlatesSection/OneServicesHoverPlatesSection'

const ServiceItem = ({
  pageContext: {
    slug: {
      metaTags,
      page_serviceDirection: {
        deviceImageType,
        title,
        blogPosts,
        blogHeader,
        showCase,
        coreHeader,
        coreItems,
        benefitsHeader,
        benefitItems,
        servicesHeader,
        serviceItems,
        portfolioHeader,
        portfolioBtn,
        portfolioItems,
        technologyMapHeader,
        technologyGroups,
        faqTitle,
        faqDescription,
        faqElements,
      },
    },
  },
  location,
}) => (
  <MainLayout seoData={metaTags} location={location}>
    <OneServiceHeader
      data={showCase}
      title={title}
      videoSrc={showCase?.videoFile?.mediaItemUrl}
      frameType={deviceImageType}
    />
    <OneServiceCore
      title={coreHeader.title}
      description={coreHeader.description}
      coreItems={coreItems}
    />
    <OneServiceBenefits
      title={benefitsHeader.title}
      description={benefitsHeader.description}
      benefitItems={benefitItems}
    />
    <OneServicesHoverPlatesSection
      title={servicesHeader.title}
      description={servicesHeader.description}
      data={serviceItems}
    />
    <OneServicePortfolio
      title={portfolioHeader.title}
      description={portfolioHeader.description}
      buttonData={portfolioBtn}
      portfolioItems={portfolioItems}
    />
    <OneServiceTechnologyMap
      title={technologyMapHeader.title}
      description={technologyMapHeader.description}
      technologyGroups={technologyGroups}
    />
    <OneServiceFaq
      title={faqTitle}
      description={faqDescription}
      faqElements={faqElements}
    />
    <OneServiceBlog data={blogPosts} heading={blogHeader} />
  </MainLayout>
)

ServiceItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired,
}
export default ServiceItem

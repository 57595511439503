import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import className from 'classnames'
import keyGenerator from '../../../../utils/keyGenerator'
import * as style from './one-service-benefits.module.scss'
import HiddenScrollItem from '../../../common/HiddenScrollItem/HiddenScrollItem'
import LeftBorderTextComponent from '../../../reusable/LeftBorderTextComponent/LeftBorderTextComponent'
import useWindowSize from '../../../../hooks/useWindowSize'

const MOBILE_WIDTH = 1200
const MAX_ITEMS = 6

const OneServiceBenefits = ({ title, description, benefitItems }) => {
  const [activeItem, setActiveItem] = useState(0)
  const activeItemToggle = (n) => () => {
    setActiveItem(n)
  }
  const size = useWindowSize()
  const hoverActiveItemToggle = (n) => () => {
    if (benefitItems.length > MAX_ITEMS && size.width > MOBILE_WIDTH) {
      setActiveItem(n)
    }
  }

  return (
    <section className={style.section}>
      <HiddenScrollItem id="benefits" />
      <div className={className('container', style.container)}>
        <div className={className(style.item, style.leftItem)}>
          <div className={style.itemContent}>
            <h2 className={style.title}>{title}</h2>
            <p className="text-color__gray--on-dark">{description}</p>
          </div>
        </div>
        <div className={className(style.item, style.rightItem)}>
          <div
            className={className(
              style.itemContent,
              benefitItems.length > 6 && style.contentGrid
            )}
          >
            {benefitItems.map((item, index) => (
              <Fade key={keyGenerator(index, item.title)} bottom>
                <div className={style.itemWrap}>
                  <LeftBorderTextComponent
                    isLight
                    title={item.title}
                    description={item.description}
                    index={index}
                    onClick={activeItemToggle}
                    onHover={hoverActiveItemToggle}
                    activeItem={activeItem}
                  />
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
export default OneServiceBenefits

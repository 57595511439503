import React from 'react'
import Fade from 'react-reveal/Fade'
import Section from '../../../common/SectionComponent/Section'
import * as style from './one-service-technology-map.module.scss'
import HiddenScrollItem from '../../../common/HiddenScrollItem/HiddenScrollItem'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'
import keyGenerator from '../../../../utils/keyGenerator'
import Chip from '../../../reusable/Chip/Chip'
import useWpContentOverflow from '../../../../hooks/useWpContentOverflow'

const MAX_ITEMS = 4

const OneServiceTechnologyMap = ({ title, description, technologyGroups }) => {
  const { toggle, isOverflow } = useWpContentOverflow('technology-map')

  const renderItem = (item, index) => {
    if (!isOverflow && index >= MAX_ITEMS) {
      return null
    }
    return (
      <Fade bottom key={keyGenerator(index, item.title)}>
        <div className={style.row}>
          <span className={style.title}>{item.title}</span>
          <div className={style.content}>
            {item.technologyGroup.technologies.map((item, index) => (
              <Chip key={keyGenerator(index, item.title)} text={item.title} />
            ))}
          </div>
        </div>
      </Fade>
    )
  }

  return (
    <Section title={title}>
      <HiddenScrollItem id="technology-map" />
      <Fade bottom>
        <p className={style.description}>{description}</p>
      </Fade>
      <div className={style.container}>{technologyGroups.map(renderItem)}</div>
      {technologyGroups.length > MAX_ITEMS && (
        <div role="button" tabIndex={0} onKeyDown={toggle} onClick={toggle}>
          <ButtonPrimary arrowDirection={isOverflow ? 'up' : 'down'}>
            {isOverflow ? 'Show Less' : 'Show More'}
          </ButtonPrimary>
        </div>
      )}
    </Section>
  )
}

export default OneServiceTechnologyMap

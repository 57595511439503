// extracted by mini-css-extract-plugin
export var animateCircle = "one-service-header-module--animate-circle--U7pjP";
export var arrow1 = "one-service-header-module--arrow1--e9NXP";
export var arrow2 = "one-service-header-module--arrow2--DkSJV";
export var arrow3 = "one-service-header-module--arrow3--aYffF";
export var bounce = "one-service-header-module--bounce--8SpTm";
export var consoleVideoWrapper = "one-service-header-module--consoleVideoWrapper--xI2Pn";
export var deviceImage = "one-service-header-module--deviceImage--0HVj-";
export var deviceVideoWrapper = "one-service-header-module--deviceVideoWrapper---NCkY";
export var glassesVideoWrapper = "one-service-header-module--glassesVideoWrapper--g9CgQ";
export var laptopVideoWrapper = "one-service-header-module--laptopVideoWrapper--XhXjG";
export var ldsRing = "one-service-header-module--lds-ring--0eTJ3";
export var mobileVideoWrapper = "one-service-header-module--mobileVideoWrapper--ZNYmH";
export var monitorVideoWrapper = "one-service-header-module--monitorVideoWrapper--hlGN1";
export var preloadImage = "one-service-header-module--preloadImage--BdB9b";
export var preview = "one-service-header-module--preview--61U4O";
export var scrollDown = "one-service-header-module--scrollDown--QVZ96";
export var scrollDownIcon = "one-service-header-module--scrollDownIcon--diJh3";
export var scrollDownText = "one-service-header-module--scrollDownText--Fre3S";
export var section = "one-service-header-module--section--Mqz4h";
export var tabletVideoWrapper = "one-service-header-module--tabletVideoWrapper--wfziP";
export var title = "one-service-header-module--title--wUjop";
export var titleWrapper = "one-service-header-module--titleWrapper--v4Wof";
export var videoRoot = "one-service-header-module--videoRoot--8eWPz";
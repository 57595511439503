import React from 'react'
import className from 'classnames'
import Fade from 'react-reveal/Fade'
import keyGenerator from '../../../../utils/keyGenerator'
import Section from '../../../common/SectionComponent/Section'
import * as style from './one-service-core.module.scss'
import HiddenScrollItem from '../../../common/HiddenScrollItem/HiddenScrollItem'

const OneServiceCore = ({ title, description, coreItems }) => {
  const renderItem = ({ title, description, link }, index) => (
    <Fade bottom key={keyGenerator(index, title)}>
      <a
        target="_blank"
        href={link.useCustomUrl ? link.customUrl : link.url?.uri}
        className={style.coreItem}
        rel="noreferrer"
      >
        <h3 className={style.coreItemTitle}>{title}</h3>
        <p className="text-20-caption-text-medium text-color__gray--on-light">
          {description}
        </p>
      </a>
    </Fade>
  )

  return (
    <Section
      isLight
      title={title}
      newClass={style.section}
      newTitleClass={style.title}
    >
      <HiddenScrollItem id="core" />
      <Fade bottom>
        <div className={style.description}>{description}</div>
      </Fade>
      <div
        className={className(
          style.coreItemContainer,
          coreItems.length > 3 && style.grid2columns
        )}
      >
        {coreItems.map(renderItem)}
      </div>
    </Section>
  )
}

export default OneServiceCore
